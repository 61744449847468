@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'codemirror/lib/codemirror.css';
@import './codemirror.less';

@layer base {
  *::selection {
    color: inherit;
  }
  a {
    @apply text-primary-0;
  }
  ul,
  h1,
  h2,
  h3,
  h4 {
    @apply m-0 p-0;
  }
}

// 去除弹窗中的取消按钮的显示
.popconfirmOverlayStyle {
  .ant-popover-buttons button:first-child {
    display: none;
  }
}

.authenticator-modal {
  .ant-modal-header {
    .ant-modal-title {
      @apply text-lg font-bold !important;
    }
  }
}

.gp-auth-card {
  @apply rounded-lg bg-white p-12;
  box-shadow: 0px 10px 47px rgba(0, 0, 0, 0.05);
}

button[type='button'].ant-switch {
  background-image: linear-gradient(to right, #c0c4cc, #c0c4cc),
    linear-gradient(to right, #fff, #fff);
  &.ant-switch-checked {
    background: #2e4b9c;
  }
}

.dropdownMenu {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 9px 16px;
    min-width: 120px;
    color: #495060;
    &.ant-dropdown-menu-item-disabled {
      color: #c0c4cc;
    }
  }
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding-top: 6px;
  padding-bottom: 6px;
}

a.dropdownBtn {
  cursor: pointer;
  font-size: 28px;
  letter-spacing: 0.2rem;
  display: block;
  height: 14px;
  line-height: 0;
  font-weight: 600;
  color: #000;
  opacity: 0.45;
  &[disabled] {
    opacity: 0.25;
    cursor: not-allowed;
  }
  &:not([disabled]):hover {
    opacity: 1;
  }
}

.stream-regular-modal {
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #dfdfdf;
    padding: 20px 56px 20px 24px;
    .ant-modal-title {
      font-weight: 700;
      font-size: 18px;
      color: #07133a;
    }
  }
  .ant-modal-body {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }
  .ant-modal-footer {
    padding: 0 24px 24px;
    border-radius: 8px;
    border-top-width: 0;
    .ant-btn {
      min-width: 110px;
      height: 40px;
      padding: 0 12px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      &:not(.ant-btn-primary) {
        color: #2e4b9c;
        border: 1px solid #2e4b9c;
        margin-right: 4px;
      }
    }
  }
}

.CodeMirror-scroll {
  margin-right: 0;
}
