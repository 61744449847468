@import 'src/variables.less'; /* Import one component's styles */

html,
body {
  font-family: 'Nunito Sans', 'Helvetica Neue', 'Helvetica, Arial', 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// 默认图标颜色
.anticon {
  color: #c0c4cc;
  display: inline-block;
}

.button-variant-primary(@color; @background) {
  &:hover,
  &:focus,
  &:active {
    & when(@background=@primary-color) {
      .button-color(@color;#3053b5;#3053b5);
    }
  }
}

.full-page.ant-result {
  @apply flex flex-col items-center justify-center pt-44;

  .ant-result-icon {
    @apply mb-10;
  }
  .ant-result-title {
    @apply mb-4;
  }
  .ant-result-subtitle {
    @apply mb-40;
  }
}

.gp-auth-card {
  width: 624px;
  .ant-result {
    @apply p-0;
  }
  .ant-result-icon > .anticon {
    font-size: 40px;
  }
}

.ant-menu {
  .ant-menu-item-group-title {
    @apply pl-6 text-xs;
  }

  &.ant-menu-inline {
    .ant-menu-item-group-title {
      @apply font-sans text-lg font-medium  text-black;
    }

    .ant-menu-submenu-open > .ant-menu-submenu-title {
      @apply text-xl font-medium  leading-none;
    }

    .ant-menu-submenu-inline {
      .ant-menu-sub {
        @apply bg-transparent;
      }
      &:hover {
        .ant-menu-submenu-arrow {
          @apply text-black;
        }
      }
    }

    .ant-menu-item {
      @apply my-0;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      @apply font-sans text-lg font-normal leading-none text-black text-opacity-80 transition-all;

      .ant-menu-submenu-arrow {
        @apply hidden;
      }
      &::after {
        @apply hidden;
      }

      &::before {
        content: '';
        height: 3px;
        @apply absolute -left-4 bottom-0 top-0 my-auto w-0 bg-black transition-all;
      }

      // 清除组件自带背景
      &.ant-menu-item-selected,
      &:hover {
        @apply bg-transparent text-black text-opacity-100;
      }

      &.ant-menu-item-selected {
        @apply relative ml-4 overflow-visible text-xl font-medium leading-none;

        &::before {
          @apply w-7;
        }
      }
    }
  }
}

.ant-form-item-label > label {
  @apply font-sans text-lg font-normal text-black text-opacity-100;
}

// 修复头像文字可能变小的问题
.ant-avatar-string {
  transform: scale(1) translateX(-50%) !important;
}

.ant-input-affix-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.ant-message-custom-content {
  @apply flex items-center;
}
