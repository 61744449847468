.cm-s-bite.CodeMirror {
  background-color: #091e420f;
  color: #172b4d;
}

.cm-s-bite .CodeMirror-gutters {
  background: #ebecf0;
  color: #626f86;
  border: none;
}

.cm-s-bite .CodeMirror-guttermarker,
.cm-s-bite .CodeMirror-guttermarker-subtle,
.cm-s-bite .CodeMirror-linenumber {
  color: #626f86;
}

.cm-s-bite .CodeMirror-cursor {
  border-left: 1px solid #5e4db2;
}
.cm-s-bite.cm-fat-cursor .CodeMirror-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-bite .cm-animate-fat-cursor {
  background-color: #5d6d5c80 !important;
}

.cm-s-bite div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.5);
}

.cm-s-bite.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.5);
}

.cm-s-bite .CodeMirror-line::selection,
.cm-s-bite .CodeMirror-line > span::selection,
.cm-s-bite .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.5);
}

.cm-s-bite .CodeMirror-line::-moz-selection,
.cm-s-bite .CodeMirror-line > span::-moz-selection,
.cm-s-bite .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.5);
}

.cm-s-bite .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-bite .cm-keyword {
  color: #05c;
  font-weight: bolder;
}

.cm-s-bite .cm-operator {
  color: #3391b7;
}

.cm-s-bite .cm-variable-2 {
  color: #00bbc7;
}

.cm-s-bite .cm-variable-3,
.cm-s-bite .cm-type {
  color: #f07178;
}

.cm-s-bite .cm-builtin {
  color: #ffcb6b;
}

.cm-s-bite .cm-atom {
  color: #d0684b;
}

.cm-s-bite .cm-number {
  color: #05c;
}

.cm-s-bite .cm-def {
  color: #3a69d0;
}

.cm-s-bite .cm-string {
  color: #216e4e;
}

.cm-s-bite .cm-string-2 {
  color: #f07178;
}

.cm-s-bite .cm-comment {
  color: #8c9bab;
}

.cm-s-bite .cm-variable {
  color: #0d1d2d;
}

.cm-s-bite .cm-tag {
  color: #ff5370;
}

.cm-s-bite .cm-meta {
  color: #c4840f;
}

.cm-s-bite .cm-attribute {
  color: #5e4db2;
}

.cm-s-bite .cm-property {
  color: #5e4db2;
}

.cm-s-bite .cm-qualifier {
  color: #decb6b;
}

.cm-s-bite .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-bite .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

.json-previewer {
  .CodeMirror {
    height: calc(90vh - 105px);
    @media screen and (min-width: 1536px) {
      height: 50vh;
    }
  }
}
